import { ProviderOidc } from '@vs/oidc-client';
import { TableActionStateProvider } from '@vs/shared-context/table-action-state-provider';
import { LocalizationProvider } from '@vs/ui/portal/time-picker';
import { ProviderMuiTheme } from '@vs/utils/mui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { oidcConfig } from '../constants/oidc';
import { store } from '../redux/store';
import AppAuthRoutes from './appContent/AppAuthRoutes';

export default function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <ProviderOidc config={oidcConfig}>
          <Provider store={store}>
            <ProviderMuiTheme>
              <TableActionStateProvider>
                <LocalizationProvider locale="zh-tw">
                  <AppAuthRoutes />
                </LocalizationProvider>
              </TableActionStateProvider>
            </ProviderMuiTheme>
          </Provider>
        </ProviderOidc>
      </BrowserRouter>
    </DndProvider>
  );
}
