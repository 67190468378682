import type * as Types from './types/operations';
import { vsDMAdminApiSlice } from './vsApiBase';

export const CapabilityKeyFragmentDoc = `
    fragment CapabilityKey on CapabilityKey {
  id
  name
  description
  updatedAt
  createdAt
}
    `;
export const DeviceModelFragmentDoc = `
    fragment DeviceModel on DeviceModel {
  id
  modelName
  osVersion
  displayName
  regExp
  note
  updatedAt
  createdAt
}
    `;
export const CapabilityVersionFragmentDoc = `
    fragment CapabilityVersion on CapabilityVersion {
  id
  fwVersion
  updatedAt
  createdAt
  deviceModel {
    ...DeviceModel
  }
}
    `;
export const DeviceCapabilityFragmentDoc = `
    fragment DeviceCapability on DeviceCapability {
  id
  note
  capability
  updatedAt
  createdAt
  capabilityKey {
    ...CapabilityKey
  }
  capabilityVersion {
    ...CapabilityVersion
  }
}
    `;
export const CustomDeviceCapabilityFragmentDoc = `
    fragment CustomDeviceCapability on CustomDeviceCapability {
      id
      note
      capability
      capabilityKey {
        id
        name
        description
      }
      capabilityVersion {
        id
        fwVersion
      }
      updatedAt
      createdAt
    }
`;
const CustomDeviceModelsDocument = `
    query CustomDeviceModels($page: Int, $limit: Int, $search: SearchCustomDeviceModels) {
  CustomDeviceModels(page: $page, limit: $limit, search: $search) {
    docs {
      id
      modelName
      osVersion
      displayName
      regExp
      note
      updatedAt
      createdAt
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    `;

const DeviceModelsDocument = `
    query DeviceModels($page: Int, $limit: Int, $sort: String) {
  DeviceModels(page: $page, limit: $limit, sort: $sort) {
    docs {
      id
      modelName
      osVersion
      displayName
      regExp
      note
      updatedAt
      createdAt
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    `;
const DeviceModelDocument = `
    query DeviceModel($id: String!) {
  DeviceModel(id: $id) {
    id
    modelName
    osVersion
    displayName
    regExp
    note
    updatedAt
    createdAt
  }
}
    `;
const CreateDeviceModelDocument = `
    mutation CreateDeviceModel($data: mutationDeviceModelInput!) {
  createDeviceModel(data: $data) {
    id
    modelName
    osVersion
    displayName
    regExp
    note
    updatedAt
    createdAt
  }
}
    `;
const UpdateDeviceModelDocument = `
    mutation UpdateDeviceModel($id: String!, $data: mutationDeviceModelUpdateInput!) {
  updateDeviceModel(id: $id, data: $data) {
    id
    modelName
    osVersion
    displayName
    regExp
    note
    updatedAt
    createdAt
  }
}
    `;
const DeleteDeviceModelDocument = `
    mutation DeleteDeviceModel($id: String!) {
  deleteDeviceModel(id: $id) {
    id
  }
}
    `;
const CustomCapabilityKeysDocument = `
    query CustomCapabilityKeys($page: Int, $limit: Int, $search: SearchCustomCapabilityKeys) {
  CustomCapabilityKeys(page: $page, limit: $limit, search: $search) {
    docs {
      ...CapabilityKey
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    ${CapabilityKeyFragmentDoc}`;
const CreateCapabilityKeyDocument = `
    mutation CreateCapabilityKey($data: mutationCapabilityKeyInput!) {
  createCapabilityKey(data: $data) {
    ...CapabilityKey
  }
}
    ${CapabilityKeyFragmentDoc}`;
const UpdateCapabilityKeyDocument = `
    mutation UpdateCapabilityKey($id: String!, $data: mutationCapabilityKeyUpdateInput!) {
  updateCapabilityKey(id: $id, data: $data) {
    ...CapabilityKey
  }
}
    ${CapabilityKeyFragmentDoc}`;
const CustomCapabilityVersionsDocument = `
    query CustomCapabilityVersions($page: Int, $limit: Int, $search: SearchCustomCapabilityVersions, $filter: FilterCustomCapabilityVersions, $sort: String) {
  CustomCapabilityVersions(page: $page, limit: $limit, search: $search, filter: $filter, sort: $sort) {
    docs {
      id
      deviceModel
      fwVersion
      updatedAt
      createdAt
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    `;
const CapabilityVersionsDocument = `
    query CapabilityVersions($page: Int, $limit: Int, $where: CapabilityVersion_where) {
  CapabilityVersions(page: $page, limit: $limit, where: $where) {
    docs {
      ...CapabilityVersion
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    ${CapabilityVersionFragmentDoc}
${DeviceModelFragmentDoc}`;
const DeleteCapabilityKeyDocument = `
    mutation DeleteCapabilityKey($id: String!) {
  deleteCapabilityKey(id: $id) {
    id
  }
}
    `;
const CustomDeviceCapabilitiesDocument = `
    query CustomDeviceCapabilities($page: Int, $limit: Int, $search: SearchCustomDeviceCapabilities, $filter: FilterCustomDeviceCapabilities, $sort: String) {
      CustomDeviceCapabilities(page: $page, limit: $limit, search: $search, filter: $filter, sort: $sort) {
        docs {
          id
          note
          capability
          capabilityKey
          capabilityVersion
          updatedAt
          createdAt
        }
        totalDocs
        totalPages
        page
        limit
        hasNextPage
        hasPrevPage
        nextPage
        prevPage
      }
    }`;
const UpdateDeviceCapabilityDocument = `
    mutation UpdateDeviceCapability($id: String!, $data: mutationDeviceCapabilityUpdateInput!) {
  updateDeviceCapability(id: $id, data: $data) {
    id
    note
    capability
    updatedAt
    createdAt
  }
}
    `;

const CreateCapabilityVersionDocument = `
    mutation CreateCapabilityVersion($data: mutationCapabilityVersionInput!) {
  createCapabilityVersion(data: $data) {
    ...CapabilityVersion
  }
}
    ${CapabilityVersionFragmentDoc}
    ${DeviceModelFragmentDoc}`;

const UpdateCapabilityVersionDocument = `
    mutation UpdateCapabilityVersion($id: String!, $data: mutationCapabilityVersionUpdateInput!) {
  updateCapabilityVersion(id: $id, data: $data) {
    ...CapabilityVersion
  }
}
    ${CapabilityVersionFragmentDoc}
    ${DeviceModelFragmentDoc}`;

const DeleteCapabilityVersionDocument = `
    mutation DeleteCapabilityVersion($id: String!) {
  deleteCapabilityVersion(id: $id) {
    id
  }
}
    `;

const CreateDeviceCapabilityDocument = `
    mutation CreateDeviceCapability($data: mutationDeviceCapabilityInput!) {
  createDeviceCapability(data: $data) {
    ...DeviceCapability
  }
}
    ${DeviceCapabilityFragmentDoc}
    ${CapabilityKeyFragmentDoc}
    ${CapabilityVersionFragmentDoc}
    ${DeviceModelFragmentDoc}`;

const DeleteDeviceCapabilityDocument = `
    mutation DeleteDeviceCapability($id: String!) {
  deleteDeviceCapability(id: $id) {
    id
  }
}
    `;

const injectedRtkApi = vsDMAdminApiSlice.injectEndpoints({
  endpoints: build => ({
    // Device Models
    CustomDeviceModels: build.query<
      Types.CustomDeviceModelsQuery,
      Types.CustomDeviceModelsQueryVariables | void
    >({
      query: variables => ({
        document: CustomDeviceModelsDocument,
        variables,
      }),
    }),

    DeviceModels: build.query<
      Types.DeviceModelsQuery,
      Types.DeviceModelsQueryVariables | void
    >({
      query: variables => ({ document: DeviceModelsDocument, variables }),
    }),

    DeviceModel: build.query<
      Types.DeviceModelQuery,
      Types.DeviceModelQueryVariables
    >({
      query: variables => ({ document: DeviceModelDocument, variables }),
    }),

    CreateDeviceModel: build.mutation<
      Types.CreateDeviceModelMutation,
      Types.CreateDeviceModelMutationVariables
    >({
      query: variables => ({ document: CreateDeviceModelDocument, variables }),
    }),

    UpdateDeviceModel: build.mutation<
      Types.UpdateDeviceModelMutation,
      Types.UpdateDeviceModelMutationVariables
    >({
      query: variables => ({ document: UpdateDeviceModelDocument, variables }),
    }),

    DeleteDeviceModel: build.mutation<
      Types.DeleteDeviceModelMutation,
      Types.DeleteDeviceModelMutationVariables
    >({
      query: variables => ({
        document: DeleteDeviceModelDocument,
        variables,
      }),
    }),

    // Capability Keys
    CustomCapabilityKeys: build.query<
      Types.CustomCapabilityKeysQuery,
      Types.CustomCapabilityKeysQueryVariables | void
    >({
      query: variables => ({
        document: CustomCapabilityKeysDocument,
        variables,
      }),
    }),

    CreateCapabilityKey: build.mutation<
      Types.CreateCapabilityKeyMutation,
      Types.CreateCapabilityKeyMutationVariables
    >({
      query: variables => ({
        document: CreateCapabilityKeyDocument,
        variables,
      }),
    }),

    UpdateCapabilityKey: build.mutation<
      Types.UpdateCapabilityKeyMutation,
      Types.UpdateCapabilityKeyMutationVariables
    >({
      query: variables => ({
        document: UpdateCapabilityKeyDocument,
        variables,
      }),
    }),

    DeleteCapabilityKey: build.mutation<
      Types.DeleteCapabilityKeyMutation,
      Types.DeleteCapabilityKeyMutationVariables
    >({
      query: variables => ({
        document: DeleteCapabilityKeyDocument,
        variables,
      }),
    }),

    // Capability Versions
    CreateCapabilityVersion: build.mutation<
      Types.CreateCapabilityVersionMutation,
      Types.CreateCapabilityVersionMutationVariables
    >({
      query: variables => ({
        document: CreateCapabilityVersionDocument,
        variables,
      }),
    }),

    UpdateCapabilityVersion: build.mutation<
      Types.UpdateCapabilityVersionMutation,
      Types.UpdateCapabilityVersionMutationVariables
    >({
      query: variables => ({
        document: UpdateCapabilityVersionDocument,
        variables,
      }),
    }),

    DeleteCapabilityVersion: build.mutation<
      Types.DeleteCapabilityVersionMutation,
      Types.DeleteCapabilityVersionMutationVariables
    >({
      query: variables => ({
        document: DeleteCapabilityVersionDocument,
        variables,
      }),
    }),

    CapabilityVersions: build.query<
      Types.CapabilityVersionsQuery,
      Types.CapabilityVersionsQueryVariables | void
    >({
      query: variables => ({
        document: CapabilityVersionsDocument,
        variables,
      }),
    }),

    CustomCapabilityVersions: build.query<
      Types.CustomCapabilityVersionsQuery,
      Types.CustomCapabilityVersionsQueryVariables | void
    >({
      query: variables => ({
        document: CustomCapabilityVersionsDocument,
        variables,
      }),
    }),

    // Device Capabilities
    CustomDeviceCapabilities: build.query<
      Types.CustomDeviceCapabilitiesQuery,
      Types.CustomDeviceCapabilitiesQueryVariables | void
    >({
      query: variables => ({
        document: CustomDeviceCapabilitiesDocument,
        variables,
      }),
    }),

    CreateDeviceCapability: build.mutation<
      Types.CreateDeviceCapabilityMutation,
      Types.CreateDeviceCapabilityMutationVariables
    >({
      query: variables => ({
        document: CreateDeviceCapabilityDocument,
        variables,
      }),
    }),

    UpdateDeviceCapability: build.mutation<
      Types.UpdateDeviceCapabilityMutation,
      Types.UpdateDeviceCapabilityMutationVariables
    >({
      query: variables => ({
        document: UpdateDeviceCapabilityDocument,
        variables,
      }),
    }),

    DeleteDeviceCapability: build.mutation<
      Types.DeleteDeviceCapabilityMutation,
      Types.DeleteDeviceCapabilityMutationVariables
    >({
      query: variables => ({
        document: DeleteDeviceCapabilityDocument,
        variables,
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as api };
export const {
  useCustomDeviceModelsQuery,
  useLazyCustomDeviceModelsQuery,
  useDeviceModelsQuery,
  useLazyDeviceModelsQuery,
  useDeviceModelQuery,
  useLazyDeviceModelQuery,
  useCreateDeviceModelMutation,
  useUpdateDeviceModelMutation,
  useDeleteDeviceModelMutation,
  useCustomCapabilityKeysQuery,
  useLazyCustomCapabilityKeysQuery,
  useCreateCapabilityKeyMutation,
  useUpdateCapabilityKeyMutation,
  useDeleteCapabilityKeyMutation,
  useCapabilityVersionsQuery,
  useLazyCapabilityVersionsQuery,
  useCustomCapabilityVersionsQuery,
  useLazyCustomCapabilityVersionsQuery,
  useCreateCapabilityVersionMutation,
  useUpdateCapabilityVersionMutation,
  useDeleteCapabilityVersionMutation,
  useCustomDeviceCapabilitiesQuery,
  useLazyCustomDeviceCapabilitiesQuery,
  useCreateDeviceCapabilityMutation,
  useUpdateDeviceCapabilityMutation,
  useDeleteDeviceCapabilityMutation,
} = injectedRtkApi;
