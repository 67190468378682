import { InputLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider as Provider } from '@mui/x-date-pickers/LocalizationProvider';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-tw';

import styles from './portal-time-picker.module.css';

const renderLabel = (
  label: string | undefined,
  required: boolean
): JSX.Element | null => {
  if (!label) return null;
  return (
    <InputLabel variant="standard" className={styles['overwrite-base-label']}>
      {label}
      {required && <span className="text-vs-error-focus ms-1">*</span>}
    </InputLabel>
  );
};

export const LocalizationProvider = ({
  children,
  locale = 'en',
}: {
  children: any;
  locale?: 'en' | 'zh-tw';
}) => {
  return (
    <Provider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      {children}
    </Provider>
  );
};

export interface PortalTimePickerProps {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  size?: 'sm' | 'md';
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disablePast?: boolean;
  disableFuture?: boolean;
  required?: boolean;
}

export function PortalTimePicker({
  value,
  onChange,
  label,
  disabled = false,
  error = false,
  helperText,
  className = '',
  size = 'md',
  minDate,
  maxDate,
  disablePast = false,
  disableFuture = false,
  required = false,
}: PortalTimePickerProps) {
  const timePickerClasses = classNames(
    styles['time-picker'],
    {
      [styles['size-sm']]: size === 'sm',
      [styles['size-md']]: size === 'md',
    },
    className
  );

  return (
    <div className={styles['overwrite']}>
      {renderLabel(label, required)}
      <DatePicker
        value={value}
        onChange={onChange}
        disabled={disabled}
        slotProps={{
          textField: {
            error,
            helperText,
            className: timePickerClasses,
            inputProps: {
              'aria-label': label,
            },
          },
        }}
        minDate={minDate}
        maxDate={maxDate}
        disablePast={disablePast}
        disableFuture={disableFuture}
      />
    </div>
  );
}

export default PortalTimePicker;
