import DomainIcon from '@mui/icons-material/Domain';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import { lazy } from 'react';

import { Path } from '.';

const corePaths: Path[] = [
  {
    path: '/users',
    name: 'Users',
    icon: <PeopleAltOutlinedIcon />,
    View: lazy(
      () => import('../../views/users' /* webpackChunkName: "view_users" */)
    ),
    childViews: [
      {
        name: 'User Detail',
        hide: true,
        path: '/:userId',
        View: lazy(
          () =>
            import(
              '../../views/users-detail' /* webpackChunkName: "view_users_detail" */
            )
        ),
      },
    ],
  },
  {
    path: '/entity-list',
    name: 'Entity List',
    icon: <DomainIcon />,
    View: lazy(
      () =>
        import(
          '../../views/entity-list' /* webpackChunkName: "view_entity_list" */
        )
    ),
    childViews: [
      {
        name: 'Entity Detail',
        hide: true,
        path: '/:entityUUID',
        View: lazy(
          () =>
            import(
              '../../views/entity-list-detail' /* webpackChunkName: "view_entity_list_detail" */
            )
        ),
      },
    ],
  },
  {
    path: '/entity-signup',
    name: 'Entity Signup',
    icon: <DomainAddIcon />,
    View: lazy(
      () =>
        import(
          '../../views/entity-signup' /* webpackChunkName: "view_entity_signup" */
        )
    ),
    childViews: [
      {
        name: 'Entity Signup Detail',
        hide: true,
        path: '/:entityUUID/:mode',
        View: lazy(
          () =>
            import(
              '../../views/entity-signup-detail' /* webpackChunkName: "view_entity_signup_detail" */
            )
        ),
      },
    ],
  },
  {
    path: '/b2b-subscriptions',
    name: 'Entity Subscriptions',
    icon: <DomainIcon />,
    activeEnv: ['dev'],
    View: lazy(
      () =>
        import(
          '../../views/b2b-subscription-list' /* webpackChunkName: "view_sales_subscription_list" */
        )
    ),
    childViews: [
      {
        name: 'Subscription Request',
        hide: true,
        path: '/create',
        View: lazy(
          () =>
            import(
              '../../views/b2b-subscription-add' /* webpackChunkName: "view_sales_subscription_add" */
            )
        ),
      },
    ],
  },
  {
    path: '/product-family',
    name: 'Product Family Management',
    icon: <LocalOfferOutlinedIcon />,
    View: lazy(
      () =>
        import(
          '../../views/product-family-management' /* webpackChunkName: "view_product_family_management" */
        )
    ),
    activeEnv: ['dev', 'stage'],
  },
  {
    path: '/email',
    name: 'Email',
    icon: <EmailOutlinedIcon />,
    childViews: [
      {
        path: '/template',
        name: 'Template',
        View: lazy(
          () =>
            import(
              '../../views/email-template-list' /* webpackChunkName: "view_email_template_list" */
            )
        ),
        childViews: [
          {
            name: 'Email Template Editor',
            hide: true,
            path: '/:languageCode/:templateCode',
            View: lazy(
              () =>
                import(
                  '../../views/email-template-editor' /* webpackChunkName: "view_email-template_editor" */
                )
            ),
          },
          {
            name: 'Add Email Template',
            hide: true,
            path: '/add/:languageCode',
            View: lazy(
              () =>
                import(
                  '../../views/email-template-editor' /* webpackChunkName: "view_email-template_editor" */
                )
            ),
          },
        ],
      },
      {
        path: '/contributor',
        name: 'Contributor',
        View: lazy(
          () =>
            import(
              '../../views/email-contributor-list' /* webpackChunkName: "view_email-contributor-list" */
            )
        ),
      },
      {
        path: '/log',
        name: 'Log',
        View: lazy(
          () =>
            import(
              '../../views/email-log-list' /* webpackChunkName: "view_email-log-list" */
            )
        ),
      },
      {
        path: '/block',
        name: 'Block',
        View: lazy(
          () =>
            import(
              '../../views/email-block-list' /* webpackChunkName: "view_email-block-list" */
            )
        ),
      },
    ],
  },
  {
    path: '/locales',
    name: 'Locale Management',
    icon: <LanguageIcon />,
    View: lazy(
      () =>
        import(
          '../../views/locale-management' /* webpackChunkName: "view_locale_management" */
        )
    ),
  },
  {
    path: '/vs-area',
    name: 'Area and Shard Region Management',
    icon: <SouthAmericaIcon />,
    View: lazy(
      () =>
        import(
          '../../views/vs-area-management' /* webpackChunkName: "view_vs_area_management" */
        )
    ),
  },
  {
    path: '/message',
    name: 'Message',
    icon: <SouthAmericaIcon />,
    childViews: [
      {
        path: '/template',
        name: 'Template',
        View: lazy(
          () =>
            import(
              '../../views/message/message-template' /* webpackChunkName: "view_message_message-template" */
            )
        ),
        childViews: [
          {
            name: 'Message Template',
            hide: true,
            path: '/:templateCode',
            View: lazy(
              () =>
                import(
                  '../../views/message/message-detail' /* webpackChunkName: "view_message_message-detail" */
                )
            ),
          },
          {
            name: 'Message Template',
            hide: true,
            path: '/add',
            View: lazy(
              () =>
                import(
                  '../../views/message/message-detail' /* webpackChunkName: "view_message_message-detail" */
                )
            ),
          },
        ],
      },
      {
        path: '/history',
        name: 'History',
        View: lazy(
          () =>
            import(
              '../../views/message/message-history' /* webpackChunkName: "view_message_message-history" */
            )
        ),
      },
    ],
  },
];

export default corePaths;
